import { createApp } from 'vue';
import VueMask from '@devindex/vue-mask';
import { configure, Field, Form, ErrorMessage, defineRule } from 'vee-validate';
import { required, email } from '@vee-validate/rules';

defineRule('required', required);
defineRule('email', email);

// Default values
configure({
  validateOnBlur: false, // controls if `blur` events should trigger validation with `handleChange` handler
  validateOnChange: false, // controls if `change` events should trigger validation with `handleChange` handler
  validateOnInput: true, // controls if `input` events should trigger validation with `handleChange` handler
  validateOnModelUpdate: false, // controls if `update:modelValue` events should trigger validation with `handleChange` handler
});

export default () => {
  const App = createApp({
    data: () => ({
      clicked: false,
      message: null,
      security: tofinoJS.nextNonce,
      success: null,
      user: {
        form: 'newsletter',
        name: '',
        lastname: '',
        email: '',
        phone: '',
        zip: '',
        interestedIn: [],
        optIn: false,
        news: false,
      },
    }),
    components: {
      // Renamed to avoid conflicts of HTML form element without a vue compiler
      VForm: Form,
      VField: Field,
      ErrorMessage: ErrorMessage,
    },
    methods: {
      isRequired (value) {
        if (value.length > 0) {
          return true;
        } else {
          return false;
        }
      },
      onSubmit (values, actions) {
          const submit = document.querySelector('[type="submit"]');
          submit.disabled = true;

          const dataToSend = Object.assign({}, this.user);
          dataToSend.phone = '+1' + this.user.phone;
          console.log('sms: ', this.user.news);

          if(!this.user.news){
              dataToSend.phone = null;
          }

          if(this.user.phone.length < 2){
              dataToSend.phone = null;
          }

          console.log('user: ', dataToSend);

      fetch(tofinoJS.ajaxUrl, {
          method: 'post',
          body: new URLSearchParams({
            action: 'ajax_newsletter_form',
            security: this.security,
            data: JSON.stringify(dataToSend),
          }),
        })
          .then(async response => {
            const data = await response.json();
            console.log(data);
            if (!data.success) {
              this.success = false;
              // console.log('-----NOT SUCCESSFUL-----');
              // console.log(data);
              if (data.type === 'validation') {
                this.$refs.newsletterForm.setErrors(data.extra);
              }
              if (data.type === '') {
                  console.log('Works1');
                this.success = true;
                const sectionForm = document.querySelector('#js-newsletter-form');
                const header = null;
                const topImage = document.querySelector('.module-marquee');
                const back = sectionForm.querySelector('.tracking-wider:first-of-type');
                const thankYou = document.querySelector('.thankyou');
                if(sectionForm){
                  sectionForm.className = 'relative overflow-hidden bg-green-400 py-18 md:py-22 lg:py-28 bridge-graphic';
                }
                if(back){
                  back.className = 'flex items-center py-3 text-sm font-bold tracking-wider uppercase md:absolute md:top-4 lg:top-8 md:left-7 lg:left-36 mb-7 md:mb-0 text-white';
                }
                [header, topImage].forEach(el => {
                  if(el){
                    el.className = 'hidden';
                  }
                });
                  if(thankYou) {
                      thankYou.className = 'py-32 text-center text-beige-200 mx-auto prose-mobile md:prose-tablet xl:prose-desktop text-beige-200 thankyou'
                  }
              }
            } else {
              this.success = true;
                console.log('Works2');
              // console.log(data);
                const sectionForm = document.querySelector('#js-newsletter-form');
                const header = null;
                const topImage = document.querySelector('.module-marquee');
                const back = sectionForm.querySelector('.tracking-wider:first-of-type');
                const thankYou = document.querySelector('.thankyou');
                if(sectionForm){
                    sectionForm.className = 'relative overflow-hidden bg-green-400 py-18 md:py-22 lg:py-28 bridge-graphic';
                }
                if(back){
                    back.className = 'flex items-center py-3 text-sm font-bold tracking-wider uppercase md:absolute md:top-4 lg:top-8 md:left-7 lg:left-36 mb-7 md:mb-0 text-white';
                }
                [header, topImage].forEach(el => {
                    if(el){
                        el.className = 'hidden';
                    }
                });
                if(thankYou) {
                    thankYou.className = 'py-32 text-center text-beige-200 mx-auto prose-mobile md:prose-tablet xl:prose-desktop text-beige-200 thankyou'
                }
            }

            this.message = data.message;
          })
          .catch(error => {
            console.error(error);
            this.message = 'A system error has occured. Please try again later.';
          });
      },
    },
  });

  App.use(VueMask).mount('#js-newsletter-form');
};
